import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  bannerImgOne,
  bannerImgTwo,
  bannerImgThree,
} from "../../assets/images";
import Image from "../designLayouts/Image";
import URL from "../../constantes";
import { Skeleton } from "@mui/material";
const Banner = () => {
  // const [urls, setUrls] = useState(slides.urls.split(','));
  const [slides, setSlides] = useState([]);
  useEffect(() => {
    const getSlides = async () => {
      try {
        const response = await fetch(URL.API_URL + "paginas/principal/slider/all");
        const data = await response.json();
        setSlides(data);
      } catch (error) {
        console.log(error);
      }
    };
    getSlides();
  }, []);
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    responsive: [
      {
        breakpoint: 576,
      },
    ],
  };
  console.log(slides?.data?.length)
  return (
    <div className="w-full bg-white pb-8">
      {slides?.data?.length > 0 ? (  
      <Slider {...settings}>
        {slides?.data?.map((slide) => (
          <Link to="/tienda" key={slide.id}>
            <div className="w-full h-full justify-center text-center">
              <img src={slide.url} alt={slide.url} className="w-full h-full object-fill"/>
            </div>
          </Link>
        ))}

      </Slider>
      ) : null
      }
      {slides?.data?.length === 0 || slides?.data?.length === undefined ? (
        <Slider {...settings}>
          {[1, 2, 3, 4].map((item, index) => (
            <div className="px-2 " key={index}>
              <Skeleton variant="rectangular" width='100%' height={450} />
            </div>
          ))}
        </Slider>
      ) : null }
    </div>
  );
};

export default Banner;
