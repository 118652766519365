import React, { Suspense, useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import Skeleton from '@mui/material/Skeleton';
import URL from "../../../constantes";

const NewArrivals = () => {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await fetch(URL.API_URL + "productos/destacados");
        const data = await response.json();
        setProductos(data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    getProducts();
  }, []);

  return (
    <div className="w-full gap-4 mb-5">
      <Heading heading="Productos Destacados" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        {productos.map((producto) => (
          <Product
            key={producto?.id}
            id={producto?.id}
            url_image={producto?.imagenes}
            imagenes={producto?.imagenes}
            nombre={producto?.nombre}
            precio={producto?.precio}
            // color="Black"
            // badge={true}
            descripcion={producto?.descripcion}
            marca={producto.nombreMarca}
            categoria={producto?.nombreCategoria}
            idMarca={producto?.idMarca}
            idCategoria={producto?.idCategoria}
            stock={producto?.stock}
          />
        ))}
      </div>
    </div>
  );
};

export default NewArrivals;

