import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import URL from "../../constantes"
const Home = () => {

  return (
    <div className="w-full mx-auto">
      <Banner/>
      {/* <BannerBottom /> */}
      <div className="max-w-container mx-auto px-4">
        {/* <Sale /> */}
        <NewArrivals />
        {/* <BestSellers /> */}
        {/* <YearProduct /> */}
        {/* <SpecialOffers /> */}
      </div>
    </div>
  );
};

export default Home;
