import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { Link } from "react-router-dom";
import axios from "axios";
import constantes from "../../../constantes";
const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [subscriptionCancel, setSubscriptionCancel] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [cancelSuscripción, setCancelSuscripción] = useState(false);
  const [cancelMsg, setCancelMsg] = useState("");
  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.\w{2,}$/);
  };

  const handleCancelSubscription = async () => {  
    console.log(emailInfo)
    if (emailInfo === "") {
      setErrMsg("Porfavor ingrese un email !");
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Porfavor ingrese un email valido!");
    } else {
      const data = await axios.post(constantes.API_URL + "newsletter/cancel", {
        email: emailInfo,
      });
      console.log(data)
      if (data.data.status === 200) {
        setSubscriptionCancel(true);
        setCancelMsg(data.data.message);
        setErrMsg("");
        setEmailInfo("");
      } else {
        setErrMsg(data.data.message);
      }
    }
  };


  const handleSubscription = async () => {
    if (emailInfo === "") {
      setErrMsg("Porfavor ingrese un email !");
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Porfavor ingrese un email valido!");
    } else {
      await axios.post(constantes.API_URL + "newsletter/add", {
        email: emailInfo,
      });
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
    }
  };
  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title="Acerca de Tienda de Autor" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
              <Link
                to="/nosotros"
                className="text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300"
              >
                Nosotros
              </Link>
              {" "}
              <span className="text-lightText">|</span>
              {" "}
              <Link
                to="/contacto"
                className="text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300"
              >
                Contacto
              </Link>
            </p>
            <ul className="flex items-center gap-2">
              <a
                href="https://www.instagram.com/tiendadeautor.ar"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaInstagram />
                </li>
              </a>
              {/* <a
                href="https://github.com/noorjsdivs"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaGithub />
                </li>
              </a> */}
              <a
                href="https://www.facebook.com/tiendadeautor.ar"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              {/* <a
                href="https://www.linkedin.com/in/noor-mohammad-ab2245193/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaLinkedin />
                </li>
              </a> */}
            </ul>
          </div>
        </div>
        <div>
          <FooterListTitle title="Otros" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <Link to={`/terminos-condiciones`}>Términos y condiciones</Link>
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <Link to={`/politica-privacidad`}>Política de privacidad</Link>
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <Link to={`/preguntas-frecuentes`}>Preguntas frecuentes</Link>
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              <Link to={`/arrepentimiento`}>Arrepentimiento</Link>
            </li>
          </ul>
        </div>
        {/* <div>
          <FooterListTitle title="Your account" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Profile
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Orders
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Addresses
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Account Details
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Payment Options
            </li>
          </ul>
        </div> */}
        {cancelSuscripción === false ? (
          <div className="col-span-2 flex flex-col items-center w-full px-4">

            <FooterListTitle title="Suscribite al newsletter." />
            <div className="w-full">
              <p className="text-center mb-4">
                y recibí las últimas noticias
              </p>
              {subscription ? (
                <motion.p
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-full text-center text-base font-titleFont font-semibold text-green-600"
                >
                  Gracias por suscribirte!
                </motion.p>
              ) : (
                <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                  <div className="flex flex-col w-full">
                    <input
                      onChange={(e) => setEmailInfo(e.target.value)}
                      value={emailInfo}
                      className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                      type="text"
                      placeholder="Ingresá tu email..."
                    />
                    {errMsg && (
                      <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                        {errMsg}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={handleSubscription}
                    className="bg-white text-lightText w-[30%] h-10 hover:bg-black hover:text-white duration-300 text-base tracking-wide"
                  >
                    Suscribirse
                  </button>
                </div>
              )}
              
              <button 
              className="text-center mt-4 text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300"
              onClick={() => setCancelSuscripción(true)}
              >
                Deseas cancelar la suscripción?{" "}
              </button>
            </div>

          </div>
        ) : (
          <div className="col-span-2 flex flex-col items-center w-full px-4">

          <FooterListTitle title="Cancelar suscripción." />
          <div className="w-full">
            <p className="text-center mb-4">
              Ingresá tu email para cancelar la suscripción
            </p>
            {subscriptionCancel ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base font-titleFont font-semibold text-green-600"
              >
               {cancelMsg}
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder="Ingresá tu email..."
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleCancelSubscription}
                  className="bg-white text-lightText w-[30%] h-10 hover:bg-black hover:text-white duration-300 text-base tracking-wide"
                >
                  Enviar
                </button>
              </div>
            )}
            
            <button 
            className="text-center mt-4 text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300"
            onClick={() => setCancelSuscripción(false)}
            >
              Suscribite acá para recibir las últimas noticias.{" "}
            </button>
          </div>

        </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
